import React, { FC, useState } from 'react';
import './user_list/UserList.scss';
import { useActions, useAppSelector } from '../../hooks/redux';
import { DateTime } from 'luxon';
import { defineEmbeddedLink, getShortMessage } from './chatUtils';
import { t } from 'i18next';
import Skeleton from '@mui/material/Skeleton';
import { IChatRoom } from '../../types';

interface ILastMessage {
  chat: IChatRoom;
}

const LastMessage: FC<ILastMessage> = ({ chat }) => {
  const { selectedChat } = useAppSelector(state => state.chatReducer);
  const { setSelectedChat, changeChat } = useActions();
  const [loadedAvatar, setLoadedAvatar] = useState<boolean>(false);

  const lastMessage = chat.messages[chat.messages.length - 1];

  const handleClickOnChat = () => {
    setSelectedChat(chat);
    changeChat(chat.id);
  };

  const formattedDate = (date: string) => {
    if (date) {
      const today = DateTime.local().toFormat('yyyy-MM-dd');
      const yesterday = DateTime.local().minus({ days: 1 }).toFormat('yyyy-MM-dd');
      const messageDate = DateTime.fromISO(date).toFormat('yyyy-MM-dd');

      if (messageDate === today) {
        return DateTime.fromISO(date).toFormat('HH:mm');
      } else if (messageDate === yesterday) {
        return t('translation:chat.yesterday');
      } else {
        const dayOfWeek = DateTime.fromISO(date).weekday;
        const daysShort = t('translation:days_short', { returnObjects: true }) as string[];
        return daysShort[dayOfWeek % 7];
      }
    }
  };

  return (
    <div onClick={handleClickOnChat}>
      <div className={selectedChat?.id == chat.id ? 'chat__user active_chat' : 'chat__user'}>
        <div className="chat__user-avatar__container">
          {chat.interlocutor.avatar ? (
            <React.Fragment>
              {!loadedAvatar && (
                <Skeleton
                  sx={{ width: '60px', height: '60px' }}
                  animation="wave"
                  variant="rectangular"
                />
              )}

              <img
                src={chat.interlocutor.avatar}
                alt="avatar"
                className="chat__user-avatar"
                onLoad={() => setLoadedAvatar(true)}
              />
            </React.Fragment>
          ) : (
            <div className="chat__user-avatar">
              <h1>{chat.interlocutor.username[0].toUpperCase()}</h1>
            </div>
          )}
        </div>

        <div className="chat__user-info">
          <div className="chat__user-name">{getShortMessage(15, chat.interlocutor.username)}</div>

          <div className="chat__user-last-message">
            {chat.messages.length > 0 ? (
              <React.Fragment>
                {lastMessage.deleted_at ? (
                  <p>{t('translation:chat.message_deleted.message')}</p>
                ) : lastMessage.phone_call ? (
                  <i>{getShortMessage(20, t('translation:chat.phone_call.message').toString())}</i>
                ) : (
                  <p>
                    {defineEmbeddedLink(lastMessage.text) ? (
                      <p className="colored">{t('translation:chat.link')}</p>
                    ) : (
                      getShortMessage(20, lastMessage.text)
                    )}
                  </p>
                )}
              </React.Fragment>
            ) : (
              <p>{t('translation:chat.no_messages.message')}</p>
            )}
          </div>
        </div>

        <div className="chat__time-last-message">
          <div className="chat__user-last-time">{formattedDate(chat.last_message_at)}</div>
          {chat.unread_messages && chat.unread_messages > 0 ? (
            <div className="chat__unread-messages-count">{chat.unread_messages}</div>
          ) : (
            <span />
          )}
        </div>
      </div>
    </div>
  );
};

export default LastMessage;
