import { DateTime } from 'luxon';
import i18next from 'i18next';
import { capitalize } from './textFormattingHelpers';

export const formatDate = (isoDateString: string, type: 'post' | 'chat' = 'post'): string => {
  const date = new Date(isoDateString);
  const currentLocale = i18next.language || 'en';

  if (type === 'post') {
    if (currentLocale === 'ru') {
      const formattedDate = new Intl.DateTimeFormat('ru-RU', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }).format(date);

      return capitalize(formattedDate);
    } else if (currentLocale === 'ka') {
      const monthIndex = date.getMonth();
      const localizedMonth = capitalize(i18next.t(`months.${monthIndex}`));
      const day = date.getDate();
      const year = date.getFullYear();

      return `${day} ${localizedMonth} ${year}`;
    } else {
      return new Intl.DateTimeFormat(currentLocale, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }).format(date);
    }
  } else if (type === 'chat') {
    const today = DateTime.local().toFormat('yyyy-MM-dd');
    const yesterday = DateTime.local().minus({ days: 1 }).toFormat('yyyy-MM-dd');
    const messageDate = DateTime.fromISO(isoDateString).toFormat('yyyy-MM-dd');

    if (messageDate === today) {
      return DateTime.fromISO(isoDateString).toFormat('HH:mm');
    } else if (messageDate === yesterday) {
      return i18next.t('translation:chat.yesterday');
    } else {
      const dayOfWeek = DateTime.fromISO(isoDateString).weekday;
      const daysShort = i18next.t('translation:chat.days_short', {
        returnObjects: true,
      }) as string[];
      return daysShort[dayOfWeek % 7];
    }
  }

  return new Intl.DateTimeFormat(currentLocale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(date);
};
