import React, { FC, useState } from 'react';
import './myPostCardHorizontal.scss';
import { IPost } from '../../../../types';
import { Skeleton } from '@mui/material';
import { t } from 'i18next';
import { getPostPrice } from '../../../../utils/get';
import { useAppSelector } from '../../../../hooks/redux';
import { MyPostsCardViews } from '../MyPostsCardViews';
import { Icon } from '../../index';
import useNavigateWithLocale from '../../../../hooks/useNavigateWithLocale';
import { formatDate } from '../../../../utils/dateFormattingHelpers';

interface IMyPostCardHorizontal {
  post: IPost;
  clickOnDelete: (post: IPost) => void;
}

const MyPostCardHorizontal: FC<IMyPostCardHorizontal> = ({ post, clickOnDelete }) => {
  const navigateWithLocale = useNavigateWithLocale();
  const { activeCurrency } = useAppSelector(state => state.authReducer);

  const [loadedImage, setLoadedImage] = useState<boolean>(false);

  const handleClickOnMyPostCard = () => {
    navigateWithLocale(`/item/${post.id}`);
  };

  const handleClickOnEditPost = () => {
    navigateWithLocale(`/editing/${post.id}`);
  };

  return (
    <section className="post-card-frame" data-cy="my-posts-card">
      <div className="post-card-main-info" onClick={handleClickOnMyPostCard}>
        <div className="post-card-image">
          {!loadedImage && <Skeleton sx={{ width: '100%', height: '100%' }} variant="rounded" />}

          <img
            src={post.images_variants[0]?.large}
            onLoad={() => setLoadedImage(true)}
            alt="Product Image"
          />
        </div>

        <div className="post-card-content">
          <div className="left-content">
            <p className="post-price">
              {post.price === null
                ? t('translation:product_page.discussed.message')
                : post.price == 0.0
                ? t('translation:product_page.free.message')
                : getPostPrice(post, activeCurrency)}
            </p>
            <p className="post-title">{post.title}</p>
            <p className="post-description">{post.description}</p>
          </div>

          <div className="right-content">{MyPostsCardViews(post)}</div>
        </div>
      </div>

      <div className="post-card-footer">
        <p className="published-at">
          {t('translation:my_posts.published_at.message')}
          {formatDate(post.created_at, 'post')}
        </p>

        <div className="post-card-action-buttons">
          {post.status === 'active' && (
            <button onClick={() => clickOnDelete(post)}>
              <Icon type="deleteType" />
              <p>{t('translation:my_posts.deactivate.message')}</p>
            </button>
          )}

          <button onClick={handleClickOnEditPost}>
            <Icon type="pencilBlackColor" />
            <p>{t('translation:my_posts.edit_info.message')}</p>
          </button>
        </div>
      </div>
    </section>
  );
};

export default MyPostCardHorizontal;
