import i18next from 'i18next';
import { useActions } from './redux';
import { localStorageKeys } from '../routes';
import { useEffect } from 'react';
import { useGetProfileInfo } from './useGetProfileInfo';

export const useInitializationLocale = () => {
  const { profileInfo } = useGetProfileInfo();
  const { setActiveLocale, setActiveCurrency } = useActions();

  const deviceLocale: string = window.navigator.language.slice(0, 2);
  const locale = localStorage.getItem(localStorageKeys.locale);
  const currency = localStorage.getItem(localStorageKeys.currency);

  useEffect(() => {
    if (profileInfo) {
      if (profileInfo.locale !== null) {
        profileInfo.locale !== locale && setLocaleToStore(profileInfo.locale);
      }

      if (profileInfo.currency !== null) {
        profileInfo.currency.toLowerCase() !== currency &&
          setCurrencyToStore(profileInfo.currency.toLowerCase());
      }
    }
  }, [profileInfo]);

  const setLocaleToStore = (locale: string) => {
    localStorage.setItem(localStorageKeys.locale, locale);
    i18next?.changeLanguage(locale);
    setActiveLocale(locale);
  };

  const setCurrencyToStore = (currency: string) => {
    localStorage.setItem(localStorageKeys.currency, currency);
    setActiveCurrency(currency);
  };

  useEffect(() => {
    if (locale && currency) {
      if (locale == 'null' || currency == 'null') {
        setLocaleToStore('ka');
        setCurrencyToStore('gel');
      } else {
        setLocaleToStore(locale);
        setCurrencyToStore(currency);
      }
    } else {
      setLocaleToStore('ka');
      setCurrencyToStore('gel');
    }
  }, []);
};
