import React, { FC, useEffect, useState, useCallback } from 'react';
import './localeCurrencySelector.scss';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { t } from 'i18next';
import { Icon } from '../../../default_components';
import { useAppSelector } from '../../../../hooks/redux';
import { IOptions } from '../../../../store/reducers/auth.slice';
import { useSaveLocaleCurrency } from '../../../../hooks/useSaveLocaleCurrency';
import { useLocation } from 'react-router-dom';

interface ILocaleCurrencySelector {
  isLocale?: boolean;
  isActivePopup: boolean;
  isCurrency?: boolean;
  translationKey: string;
  options: IOptions[];
}

const LocaleCurrencySelector: FC<ILocaleCurrencySelector> = ({
  isLocale,
  isActivePopup,
  isCurrency,
  translationKey,
  options,
}) => {
  const location = useLocation();
  const { activeLocale, activeCurrency } = useAppSelector(state => state.authReducer);

  const [isActiveSelector, setIsActiveSelector] = useState(false);
  const { saveOption } = useSaveLocaleCurrency();

  useEffect(() => {
    if (!isActivePopup) {
      setIsActiveSelector(false);
    }
  }, [isActivePopup]);

  const handleOptionClick = useCallback(
    (option: IOptions) => {
      if (option === activeLocale || option === activeCurrency) return;

      saveOption({
        option,
        isLocale,
        isCurrency,
      });

      if (isLocale) {
        const newLocale = `/${option.value}`;

        setTimeout(() => {
          const currentPath = location.pathname;
          const newPath = currentPath.match(/^\/(en|ka|ru)/)
            ? currentPath.replace(/^\/(en|ka|ru)/, newLocale)
            : `${newLocale}${currentPath}`;

          window.history.replaceState(null, '', newPath);
        }, 0);
      }
    },
    [activeLocale, activeCurrency, isLocale, saveOption, location.pathname],
  );

  return (
    <div className="selector__container">
      <div
        onClick={() => setIsActiveSelector(!isActiveSelector)}
        className="selector__container-title"
      >
        {t(translationKey)}:
        <KeyboardArrowDownIcon
          className={`selector-arrow ${isActiveSelector && 'selector-arrow-up'}`}
        />
      </div>

      <div className={`selector__select ${isActiveSelector && 'show__selector__select'}`}>
        {options.map(option => (
          <div
            key={option.value}
            className={`selector__option ${isActiveSelector && 'show__selector__select'} ${
              (activeLocale.value === option.value || activeCurrency.value === option.value) &&
              'selector__option-active'
            }`}
            onClick={() => handleOptionClick(option)}
          >
            <Icon type={option.value} />

            <div className="selector__option__title">{option.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LocaleCurrencySelector;
