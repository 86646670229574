import React, { FC } from 'react';
import './postTitle.scss';
import { IPost } from '../../../../../types';
import { Skeletons } from '../../../../default_components';
import i18next from 'i18next';

interface IPostTitle {
  post: IPost | undefined;
  isPostFetching: boolean;
}

const PostTitle: FC<IPostTitle> = ({ post, isPostFetching }) => {
  const currentLocale = i18next.language || 'en';

  const formatGeorgianDate = (date: Date): string => {
    const day = date.getDate();
    const months = i18next.t('months', { returnObjects: true }) as string[];
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${day} ${month} ${year}, ${hours}:${minutes}`;
  };

  const formattedDate = (): string | undefined => {
    if (post?.created_at) {
      const date = new Date(post.created_at);
      return currentLocale === 'ka'
        ? formatGeorgianDate(date)
        : new Intl.DateTimeFormat(currentLocale, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          }).format(date);
    }
  };

  return (
    <div className="post-title-data_frame">
      {post?.title && !isPostFetching ? (
        <h1 className="post-title">{post?.title}</h1>
      ) : (
        <Skeletons.SkeletonPostTitle />
      )}

      {post && !isPostFetching ? (
        <p className="post-creation-data">{formattedDate()}</p>
      ) : (
        <Skeletons.SkeletonPostCategory />
      )}
    </div>
  );
};

export default PostTitle;
